<template>
  <div id="loading">
    <div>
      <p v-if="typeof loading === 'boolean'">{{ $t("utils.loading.default") }}</p>
      <p v-else v-html="$t(`utils.loading.${loading}`)" />
      <font-awesome-icon icon="spinner" size="4x" spin />
    </div>
  </div>
</template>

<script>
export default ({
  name: 'Loading',
  props: {
    loading: { type: Boolean || String, default: false },
  }
})
</script>

<style lang="scss" scoped>
#loading {
  opacity: 0.8;
  height: 100%;
  width: 100%;
  text-align: center;
}

#loading > div {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

svg {
  color: $colorbase-dark;
}
</style>
