export const state = {
    objects: [],
}

export const mutations = {
    set_objects(state, objects) {           
        state.objects = objects
    },
}

export const actions = {
    setObjects({ commit }, objects) {
        commit('set_objects', objects.map((o) => ({ ...o, name: o.name.replace('"', '')})))
    },
}

export const getters = {
    objects: (state) => {
        return state.objects
    },
}