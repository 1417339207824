<template>
  <div @click="openMenu()">
    <ul :class="'level' + level">
      <div v-for="item in items"
        :key="item.title"
      >
        <li
          v-if="item.loggedIn === undefined || item.loggedIn === isLoggedIn"
          class="sidebar-item"
          :id="item.title"
        >
          <ItemWithChildren
            v-if="item.children && item.children.length"
            :item="item"
            :isCompact="isCompact"
            @switchOpen="item.isOpen = !item.isOpen"
          />
          <router-link v-else :to="$i18nRoute({ name: item.to })">
            <div class="appearing">
                <font-awesome-icon
                  v-if="item.icon !== undefined"
                  :icon="item.icon"
                  :size="isCompact ? 'lg' : null"
                />
                <span v-if="!isCompact">
                  {{ $t(translatePrefix + item.title) }}
                </span>
            </div>
          </router-link>
          <b-tooltip
            v-if="isCompact"
            variant="dark"
            :target="item.title"
            :title="$t(translatePrefix + item.title)"
            placement="right"
          ></b-tooltip>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import ItemWithChildren from "./ItemWithChildren.vue"
import AuthMixin from '@/mixins/api/auth'
export default {
  name: "SidebarItems",
  components: {
    ItemWithChildren
  },
  mixins: [AuthMixin],
  props: {
    items: { type: Array, required: true },
    level: { type: Number, default: 1 },
    isCompact: { type: Boolean, default: true }
  },
  data() {
    return {
      translatePrefix: "sidebar.",
    }
  },
  methods: {
    openMenu() {
      this.$emit("open-menu")
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  text-align: left;
}

span {
  margin-left: 0.5em;
}

.level1 {
  margin: 2em 1em;
  font-size: 1.2em;
}

.level1 li {
  margin: 2em 0em;
}

.level2 {
  font-size: 0.8em !important;
  border-bottom: 1px solid $colorbase-light;
}

.level2 li {
  margin: 1em 0;
  padding: 0 0.7em;
}

a,
a.router-link-active {
  color: $colorbase-lightergrey;
}

a:hover,
a.router-link-active:hover {
  color: $colorbase-lighter;
  text-decoration: none;
}

a.router-link-exact-active {
  color: $colorbase !important;
}

.appearing {
  overflow: hidden; 
  white-space: nowrap;
}
</style>
