import { mapGetters, mapActions } from 'vuex'
import axios from '@/plugins/axios'
import { BToast } from 'bootstrap-vue';
export default {
    components: {
        BToast
      },
    data() {
        return {
            loading: false,
            error: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['user', 'username', 'email', 'isLoggedIn', 'fullName', 'hasUsersRights']),
    },
    methods: {
        ...mapActions('auth', ['attemptLogin', 'logout', 'passwordChange', 'sendPasswordReset', 'resetPassword']),
        login(credentials) {
            this.attemptLogin({ aspNetRole: this.$staticVariables.getAspNetRole(), ...credentials })
            .then(() => this.$router.push({ name: 'Dashboard' }))
            .catch((error) => {
                this.errorMessage = null
                this.displayPin = false
                var type = error.response.data.error
                switch (type) {                    
                    case "access_level":
                        this.errorMessage = this.$t("general.user_access_level")
                        break
                    case "invalid_company":
                        this.errorMessage = this.$t("general.user_company_error")
                        break
                    case "wrong_dashboard":
                        this.errorMessage = this.$t("general.wrong_dashboard");
                        break
                    case "pin_needed":
                        this.displayPin = true
                        break
                    case "pin_wrong":
                        this.displayPin = true
                        this.errorMessage = this.$t("general.pin_wrong");
                        break
                    case "invalid_grant":
                        this.errorMessage = this.$t("general.user_credentials_error")
                        break
                }
            })
        },    
        async loadSettings() {
            this.loading = true
            return await axios.post(`api/Account/FetchUserData`, {
                requester: this.email,
                aspNetRole: null,
            }).then((resp) => {
                return this.formattedSettings(JSON.parse(resp.data))                
            }).catch((err) => {
                this.error = true
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        },
        async updateSettings(key, form) {
            const newSettings = { ...this.settings }
            newSettings[key] = { ...form }
            return await axios.post('api/Account/EditSettings', {
                requester: this.email,
                ...this.unformattedSettings(newSettings),
            }).then((resp) => {
                this.$bvToast.toast(this.$t(`toasts.settings.success.body`, { key: this.$t(`settings.${key}.title`) }), {
                    title: this.$t(`toasts.settings.success.title`),
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                    appendToast: true,
                })
                return this.formattedSettings(JSON.parse(resp.data))                
            }).catch((err) => {
                console.log(err)
                this.$bvToast.toast(this.$t(`toasts.settings.failure.body`, { key: this.$t(`settings.${key}.title`) }), {
                    title: this.$t(`toasts.settings.failure.title`),
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                    appendToast: true,
                })
            })
        },
        attemptPasswordChange(form) {
            this.passwordChange(form)
                .then((resp) => {
                    console.log(resp)
                    this.$refs['success-modal'].show()
                    this.$bvToast.toast(this.$t(`toasts.settings.success.body`, { key: this.$t(`settings.${key}.title`) }), {
                        title: this.$t(`toasts.settings.success.title`),
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                        appendToast: true,
                    })
                    this.sucessMessage = true;
                    setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                }).catch((err) => {
                    console.log(err)
                    this.$refs['fail-modal'].show()
                    this.$bvToast.toast(this.$t(`toasts.settings.failure.body`, { key: this.$t(`settings.${key}.title`) }), {
                        title: this.$t(`toasts.settings.failure.title`),
                        variant: 'danger',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                        appendToast: true,
                    })

                 
                })
        },
        async callAuthenticatorSetup() {
            return new Promise((resolve, reject) => {
                axios.post('api/Account/GetFirstAuthenticator', {
                    requester: this.email,
                }).then((resp) => {
                    resolve(resp.data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        async callAuthenticatorCheck(pin) {
            return new Promise((resolve, reject) => {
                axios.post('api/Account/ValidateFirstAuthenticator', {
                    requester: this.email,
                    pin,
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        formattedSettings(rawSettings) {
            return {
                Profile: {
                    FirstName: rawSettings.FirstName,
                    LastName: rawSettings.LastName,
                    PhoneNumber: rawSettings.PhoneNumber,
                    PhoneNumberConfirmed: rawSettings.PhoneNumberConfirmed,                        
                },
                Account: {
                    Email: rawSettings.Email,
                    EmailConfirmed: rawSettings.EmailConfirmed,
                    UserName: rawSettings.UserName,
                    TwoFactorEnabled: rawSettings.TwoFactorEnabled,
                }
            }
        },
        unformattedSettings(formattedSettings) {
            return {
                FirstName: formattedSettings.Profile.FirstName,
                LastName: formattedSettings.Profile.LastName,
                PhoneNumber: formattedSettings.Profile.PhoneNumber,
                Email: formattedSettings.Account.Email,
                UserName: formattedSettings.Account.UserName,
                TwoFactorEnabled: formattedSettings.Account.TwoFactorEnabled,
            }
        },
    },
}