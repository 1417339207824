import axios from '@/plugins/axios'
import moment from "moment"
import qs from "qs"

export const state = {
  status: '',
  user: null,
}
  
export const getters = {
  user(state) {
    return state.user
  },
  isLoggedIn(state) {
    if (!state.user?.token) {
      delete axios.defaults.headers.common["Authorization"]
    }
    return !!state.user?.token
  },
  hasUsersRights(state) {
    return state.user?.main_role === 'dash_ot' || state.user?.main_role === 'dash_admin'
  },
  companyId: (state) => {
    return state.user?.company_id
  },
  token: (state) => {
    return state.user?.token
  },
  authStatus: (state) => {
    return state.status
  },
  username: (state) => {
    return state.user?.username
  },
  email: (state) => {
    return state.user?.email
  },
  fullName: (state) => {
    return state.user?.fullname
  },
  mainRole: (state) => {
    return state.user?.main_role
  },
  rights: (state) => {
    return state.user?.rights
  },
  accessRights: (state) => {
    return state.user?.rights
  },
  activity: (state) => {
    return moment(state.user?.lastActivity)
  },
  hasPermission: (state) => {
    // check if token is defined
    if (!state.user?.token || state.user?.token === undefined || state.user?.token === "") {
      return false
    }
    // check if token validity time is not over
    else if (state.user?.maxTokenValidity === undefined || state.user?.maxTokenValidity === "" ||
      moment().isAfter(state.user?.maxTokenValidity)) {
      return false
    }
    // check if account has been active in the last 3 hours
    else if (state.user?.lastActivity === undefined || state.user?.lastActivity === "" ||
      moment().diff(state.user?.lastActivity, 'hours') > 2) {
      return false
    }
    else {
      return true
    }
  },
}

export const mutations = {
  auth_request(state) {
    state.status = "loading"
  },
  auth_success(state, data) {
    state.status = "success"
    state.user = {
      token: data.access_token,
      fullname: data.fullname,
      username: data.userName,
      email: data.email,
      company_id: Number(data.companyId),
      main_role: data.mainRole,
      rights: JSON.parse(data.menu).accessRights,
      lastActivity: moment(),
      maxTokenValidity: moment().add(1, 'days'),
    }
  },
  auth_error(state) {
    state.status = "error"
  },
  logout(state) {
    state.status = ""
    state.user = null
  },
  setLastActivity(state, date) {
    state.user.lastActivity = date
  },
}

export const actions = {
  async attemptLogin({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("auth_request")
      let username = credentials.email
      let password = credentials.password
      let aspNetRole = credentials.aspNetRole
      let pin = credentials.pin
      let data = qs.stringify({ username, password, aspNetRole, grant_type: "password" })
      axios.post(`token${pin ? `?pin=${pin}` : ''}`, data)
        .then((resp) => {
          // add token to default headers for axios
          axios.defaults.headers.common["Authorization"] = resp.data.access_token
          commit("auth_success", { ...resp.data, email: credentials.email })
        })
        .catch((err) => {
          commit("auth_error")
          reject(err)
        })
        .finally((resp) => {
          resolve(resp)
        })
    })
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout")
      delete axios.defaults.headers.common["Authorization"]
      resolve()
    }).then(() => {
      document.location.href = "/"
    })
  },
  async passwordChange({ commit, state }, password) {
    const data = {
      OldPassword: password.oldPassword,
      NewPassword: password.newPassword,
      ConfirmPassword: password.confirmPassword,
    }
    const config = { 
      headers: {
        'Authorization': `Bearer ${state.user.token}`,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post("api/Account/ChangePassword", data, config)
        .then((resp) => {
          resolve(resp)
          return resp
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async sendPasswordReset({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("request_sent")
      axios
        .post(
          "api/Account/resetEmailMyTourobs",
          {
            email: user.email,
            language: user.lang,
            company: user.aspNetRole,
          }
        )
        .then((resp) => {
          resolve(resp)
          return resp
        })
        .catch((err) => {
          commit("password_error")
          reject(err)
        })
    })
  },
  async resetPassword({ commit }, password) {
    return new Promise((resolve, reject) => {
      commit("request_sent")
      axios
        .post(
          "api/Account/ResetPassword",
          {
            email: password.userHash,
            token: password.token,
            password: password.password
          }
        )
        .then((resp) => {
          resolve(resp)
          return resp
        })
        .catch((err) => {
          commit("password_error")
          reject(err)
        })
    })
  },
}
  