<template>
  <div class="carret">
    <span v-if="open">&#9206;</span>
    <span v-if="!open">&#9207;</span>
  </div>
</template>

<script>
export default {
  props: {
    open: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.carret {
  margin-right: 0.3em;
  float: right;
}
</style>
