<template>
  <div>
    <div @click="$emit('switchOpen')">
      <a :class="{ opened: item.isOpen, active: item.isActive }">
        <b-row>
          <b-col cols="1"
            ><font-awesome-icon
              v-if="item.icon !== undefined"
              :icon="item.icon"
              :size="isCompact ? 'lg' : null"
            />
          </b-col>
          <b-col>
            <span :class="isCompact ? 'hidden' : ''">
              {{ $t(translatePrefix + item.title) }}
            </span></b-col
          >
          <b-col cols="2">
            <Carret :class="isCompact ? 'hidden' : ''" :open="item.isOpen" />
          </b-col>
        </b-row>
      </a>
    </div>
    <sidebar-items
      v-if="item.isOpen"
      :items="item.children"
      :level="item.level + 1"
      :isCompact="isCompact"
    />
  </div>
</template>

<script>
import Carret from "@/components/utils/Carret.vue";
export default {
  name: "ItemWithChildren",
  components: {
    Carret
  },
  props: {
    isCompact: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  data() {
    return {
      translatePrefix: "sidebar."
    };
  }
};
</script>

<style lang="scss" scoped>
a:hover * {
  color: $colorbase-lighter;
}

.opened {
  color: $colorbase-lighter;
}

.active {
  color: $colorbase;
}
</style>
