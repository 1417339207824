export const state = {
    members: [],
}

export const mutations = {
    set_members(state, members) {           
        state.members = members
    },
}

export const actions = {
    setMembers({ commit }, members) {
        commit('set_members', members.map((o) => ({ ...o, name: o.name.replace('"', '')})))
    },
}

export const getters = {
    members: (state) => {
        return state.members
    },
}