<template>
  <div id="app">
    <Navbar ref="navbar" />
    <Sidebar ref="sidebar" />
    <div class="content">
      <loading v-if="loading" />
      <Content v-else @sidebar="closeSidebar()" />
    </div>
    <Footer ref="footer" />
  </div>
</template>

<script>
import Navbar from "@/components/menus/navbar/Navbar.vue"
import Sidebar from "@/components/menus/sidebar/Sidebar.vue"
import Content from "@/components/utils/Content.vue"
import Footer from "@/components/menus/Footer.vue"
import ListsMixin from '@/mixins/api/lists'
export default {
  components: {
    Navbar,
    Sidebar,
    Content,
    Footer
  },
  mixins: [ListsMixin],
  created() {
    this.fetchLists()
  },
  methods: {
    closeSidebar() {
      this.$refs.sidebar.escapeHandler()
    },
  },
  metaInfo() {
    return { 
      title: this.$t('meta.' + this.$route.name ),
      titleTemplate: (title) => {
        const suffix = this.$t('meta.suffix')
        return `${title} - ${suffix}`
      },
    }
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: "Brown";
  src: url("~@/assets/styles/BrownMedium Regular.ttf") format('truetype');
}

body {
  background-color: $colorbase-background !important;
  margin: 0;
}

#app {
  font-family: Brown, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.leaflet-container {
  font-family: Brown, sans-serif !important;
}

h1,
h2,
h3 {
  text-align: center;
  padding-bottom: 1em;
}

.content {
  padding: 3em 3em 2em 9em;
  min-height: 98vh;
}

.positive {
  color: forestgreen;
}

.negative {
  color: $colorbase;
}

.text-colorbase {
  color: $colorbase !important;
}

/* Google Chart Table */
.google-visualization-table {
  text-align: left;
}
.google-visualization-table-th.gradient {
  background: $colorbase-darker !important;
  color: white !important;
}
.google-visualization-table-div-page {
  padding: 0.5em !important;
  background-color: white !important;
}
.google-visualization-table-page-next,
.google-visualization-table-page-prev,
.goog-custom-button-inner-box,
.goog-custom-button-outer-box,
.google-visualization-table-page-number {
  background: $colorbase-dark !important;
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.3em 0.5em !important;
}
.goog-inline-block.goog-custom-button {
  margin: 0 0.2em !important;
}
.google-visualization-table-page-numbers {
  margin-left: 2em !important;
}
.google-visualization-table-page-number.current {
  background: $colorbase-darker !important;
}
.table {
  color: #2c3e50;
}
.y-scrollable {
  overflow-y: auto;
}
</style>
