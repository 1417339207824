<template>
  <div id="footer" class="d-flex justify-content-between align-items-center py-2 px-3">
    <span />
    <a href="https://tourobs.ch" target="_blank">
      <img :src="require(`@/assets/logo/TourobsLogo.png`)" height="40" />
    </a>
    <span>© Tourobs {{ currentYear }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: "footer.",
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  }
}
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  color: $colorbase-grey;
  background: $navbar-bg;
  border-top: 2px solid $colorbase;
  text-align: right;
}
</style>
