<template>
  <div
    id="sidebar"
    :class="{ compact: isCompact, hidden: !appear }"
    v-cloak
    @mouseleave="reduceBar()"
    @mouseover="isCompact = false"
  >
    <SidebarItems
      :items="menu"
      :isCompact="isCompact"
      @open-menu="openMenu()"
    />
  </div>
</template>

<script>
import SidebarItems from "./SidebarItems.vue";
import { Escapable } from "@/mixins/utils/Escape";
export default {
  components: {
    SidebarItems
  },
  mixins: [Escapable],
  data() {
    return {
      isCompact: true,
      appear: false,
      menu: [
        {
          to: "Dashboard",
          title: "dashboard",
          icon: "chart-pie",
          level: 1
        },     
        {
          to: "Members",
          title: "members",
          icon: "users",
          level: 1,
          loggedIn: true,
        },
        {
          to: "hestaprereport",
          title: "hestaprereport",
          icon: "chart-bar",
          level: 1,
          loggedIn: true,
        }
        /*
        **
        ** Menu Item exemple, with sub-menus
        **
        {
          title: "hosting",
          icon: "home",
          isOpen: false,
          isActive: false,
          level: 1,
          children: [
            {
              to: "TouristicOvernights",
              title: "overnights",
              icon: "moon",
              level: 2
            },
            {
              title: "hotels",
              icon: "hotel",
              isOpen: false,
              isActive: false,
              level: 2,
              children: [
                {
                  to: "Wallis",
                  title: "hesta_wallis",
                  level: 3
                },
                {
                  to: "Switzerland",
                  title: "hesta_switzerland",
                  level: 3
                }
              ]
            },            
          ],
        },
        */
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.appear = true
    }, 400)
    this.setParentActiveOnMounted()
  },
  methods: {
    openMenu() {
      if (this.isCompact) {
        this.isCompact = false
      }
    },
    resizeNavbar() {
      this.isCompact = !this.isCompact
      if (this.isCompact) {
        this.manageAccordion(false)
      }
    },
    manageAccordion(open) {
      this.$nextTick(() => {
        this.menu.forEach(item => {
          item.isOpen = open
          if (item.children && item.children.length) {
            item.children.forEach(child => {
              child.isOpen = open
            })
          }
        })
      })
    },
    resetActive() {
      this.isCompact = true
      const root = { isActive: false, children: this.menu }
      this.resetActiveNode(root)
    },
    resetActiveNode(node) {
      if (node.children === undefined) return
      node.children.forEach(child => {
        if (child.isActive !== undefined) child.isActive = false
        this.resetActiveNode(child)
      })
    },
    setParentActive(node, path, i) {
      if (path[i] === undefined) return
      const child = node.children.find(
        m => m.title === path[i].replace("-", "")
      )
      child.isActive = true
      this.setParentActive(child, path, i + 1)
    },
    reduceBar() {
      this.manageAccordion(false)
      this.isCompact = true
    },
    escapeHandler() {
      this.reduceBar()
    },
    setParentActiveOnMounted() {
      const path = this.$route.fullPath.split("/")
      path.splice(0, 2)
      if (path.length > 0) {
        // init
        const rootNode = { children: this.menu }
        this.setParentActive(rootNode, path, 0)
      }
    }
  },
  watch: {
    $route(to) {
      this.resetActive()
      // Get children names
      const path = to.fullPath.split("/")
      path.splice(0, 2)
      path.pop()
      if (path.length > 0) {
        // init
        const rootNode = { children: this.menu }
        this.setParentActive(rootNode, path, 0)
      }
    }
  }
}
</script>

<style lang="scss">
#sidebar {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: $navbar-height;
  bottom: 0;
  z-index: 1001;
  width: $sidebar-width;
  background-color: $navbar-bg;
  border-right: 2px solid $colorbase;
  color: $colorbase-darkergrey;
  background-image: -moz-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    ),
    url($backgroundurl);
  background-image: -webkit-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    ),
    url($backgroundurl);
  background-image: -ms-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    ),
    url($backgroundurl);
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    ),
    url($backgroundurl);
  background-size: cover;
  animation-name: appear;
  animation-duration: 0.6s;
  transition: width 0.2s;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $colorbase-lightest;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $colorbase-light;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $colorbase-light;
  }
}

@keyframes appear {
  0% {
    left: -100%;
  }
  25% {
    left: -66%;
  }
  50% {
    left: -33%;
  }
  100% {
    left: 0;
  }
}

#sidebar.compact {
  overflow: hidden;
  width: 75px;
}

#sidemenu {
  margin: 0;
  padding: 0.8em;
  text-align: left;
  color: $colorbase-darkergrey;
}

.sidebar-item {
  cursor: pointer;
}

.hidden {
  display: none;
}

#sidebar ul,
#sidebar li {
  list-style-type: none;
}
</style>
