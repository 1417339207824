export const global = {
  getDestinationParams() {
    const params = {}
    if(process.env.VUE_APP_CANTON_ID !== '0')
      params.canton = process.env.VUE_APP_CANTON_ID
    if(process.env.VUE_APP_DESTINATION_ID !== '0')
      params.destination = process.env.VUE_APP_DESTINATION_ID
    return params
  },
  formatNumber: number => {
    return new Intl.NumberFormat("fr-FR").format(number)
  },
  distinct: (array, key) => {
    return [...new Set(array.map(x => x[key]))]
  },
  includes: (array, value) => {
    let cpt = 0
    array.forEach(line => {
      if (line === value) cpt++
    })
    return cpt > 0
  },
  scrollTop: () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  },
  isPositive(current, before) {
    return current >= before
  },
  computeDifference(current, before) {
    return (current - before) / before * 100
  },
  formatToFixed(val) {
    const fixed = Math.round(val * 100) / 100
    return this.formatNumber(fixed)
  },
}

