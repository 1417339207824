import Vue from 'vue'
import App from './App.vue'

// Our global components
import { global } from "@/mixins/utils/Global.js"
import { StaticVar } from "@/mixins/utils/StaticVariables.js"
import SidebarItems from "@/components/menus/sidebar/SidebarItems.vue"
import Loading from "@/components/utils/Loading.vue"
import ApiError from "@/components/utils/ApiError.vue"
import SearchBar from "@/components/utils/SearchBar.vue"
import AHVLogo from '@/components/utils/AHVLogo.vue'

// Plugins
import router from "./router"
import store from "./store"
import { i18n } from './plugins/i18n'
import { Trans } from './plugins/i18n/translationHandler'
import VueGoogleCharts from 'vue-google-charts'
import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import './plugins/element-ui'
import './plugins/core-ui'
import './plugins/multiselect'
import './plugins/axios'
import './plugins/google-charts'
import './plugins/apex-charts'
import './plugins/leaflet'
import './plugins/vue-meta'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("search-bar", SearchBar)
Vue.component("sidebar-items", SidebarItems)
Vue.component("loading", Loading)
Vue.component("api-error", ApiError)
Vue.component("ahv-logo", AHVLogo)
Vue.component("vSelect", vSelect)
Vue.use(VueGoogleCharts)

Vue.config.productionTip = false

Vue.prototype.$utils = global;
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.prototype.$staticVariables = StaticVar

// filters
Vue.filter('formatNumber', function (number) {
  if (!number) return '';
  return number.toLocaleString("fr-CH", { maximumFractionDigits: 1, minimumFractionDigits: 0 });
});

Vue.filter('formatPercent', function (number) {
  if (!number) return '';
  return number.toLocaleString("fr-CH", { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 });
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
