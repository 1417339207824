<template id="lang">
  <b-nav-item-dropdown right>    
    <template #button-content>
      <font-awesome-icon class="mr-2" icon="globe" />
      <span v-html="$t('navbar.lang')" />
    </template>
    <b-dropdown-item
      v-for="locale in locales" 
      :key="locale"
      @click="switchLocale(locale)"
    >
      <img class="mr-2" :src="require(`@/assets/images/${locale}.png`)" width="20" />
      <span v-html="$t('navbar.' + locale)" />
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { Trans } from '@/plugins/i18n/translationHandler'
export default {
  name: 'LanguageSwitcher',
  computed: {
    locales() {
      return Trans.supportedLocales
    },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } })
          return Trans.changeLocale(locale).then(() => {
            this.$router.push(to.location)
        })            
      }
    },
  }
}
</script>
