<template>
    <img :src="require(`@/assets/images/ahv-logo.jpg`)" :height="height" />
</template>

<script>
export default {
    name: 'AHVLogo',
    props: {
        height: { type: Number || String, default: 20 },
    },
}
</script>

<style>

</style>