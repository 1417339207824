export const state = {
    flags: [],
}

export const mutations = {
    set_flags(state, flags) {           
        state.flags = flags
    },
}

export const actions = {
    setFlags({ commit }, data) {
        commit('set_flags', data.flags)
    },
}

export const getters = {
    flags: (state) => {
        return state.flags
    },
}