export const StaticVar = {
    getCanton() {
        return parseInt(process.env.VUE_APP_CANTON_ID)
    },
    getDestination() {
        return parseInt(process.env.VUE_APP_DESTINATION_ID)
    },
    /*
    ** Set the ID of the global touristic spot if there is one, i.e. the whole canton
    ** Set to zero if not needed
    */
    getGlobalSpotId() {
        return parseInt(process.env.VUE_APP_GLOBAL_SPOT_ID)
    },
    /*
    ** Set the role name that will be use in Backend to check if the user has access
    ** to this specific Dashboard
    */
    getAspNetRole() {
        return process.env.VUE_APP_ASP_NET_ROLE
    },
}
  