import axios from '@/plugins/axios'
import { Trans } from '@/plugins/i18n/translationHandler'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        ...mapActions('flags', ['setFlags']),
        async fetchLists() {
            Promise.all([this.fetchFlags()])
                .then((res) => {
                    this.setFlags(res[0].data)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchFlags() {
            return axios.get(`api/AHV/flags`)
        },
    },
}